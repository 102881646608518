"use client";

import { makeAssistantToolUI } from "@assistant-ui/react";
import { Table, TableColumnType, Switch } from "antd";
import { SortOrder } from "antd/es/table/interface";
import "./DisplayDataTool.css";
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import annotationPlugin from "chartjs-plugin-annotation";
import PlotButton from "./PlotButton";
import PlotDisplay from "./PlotDisplay";

import { useState, useEffect } from "react";

Chart.register(...registerables, annotationPlugin);

// I don't know why we need this. Placeholder for now.
type DisplayDataToolArgs = {
  placeholder: string;
};

// Given a string like "o25 (-100)",
// return the odds line, or 25 in this case.
const extractNumberFromOdds = (value) => {
  const match = value.match(/[a-zA-Z]\s*(\d+(\.\d+)?)/); // Match number (integer or decimal) after a letter
  return match ? parseFloat(match[1]) : null;
};

// Define the structure of a table row
export type TableRow = {
  [key: string]: string | number | null; // Adjust this type based on your actual data
};

export const DisplayDataTool = makeAssistantToolUI<
  DisplayDataToolArgs,
  string
>({
  toolName: "display_data",
  render: function DisplayDataUi({ result }) {
    const [selectedPlot, setSelectedPlot] = useState<string>();
    const [columns, setColumns] = useState<TableColumnType<TableRow>[]>([]);
    const [rows, setRows] = useState<TableRow[]>([]);
    const [isBettingInfoEnabled, setIsBettingInfoEnabled] = useState<boolean>(false);

    const table: TableRow[] = result ? JSON.parse(result as string) : [];

    useEffect(() => {
      const keys = isBettingInfoEnabled 
        ? Object.keys(table[0]) 
        : Object.keys(table[0]).filter((key) => !key.toLowerCase().includes("(line)"));
 
      // Update columns and rows when toggleState changes
      const newColumns: TableColumnType<TableRow>[] = keys.map((key) => ({
        title: key.toUpperCase(),
        align: 'center',
        dataIndex: key,
        key: key,
        sorter: (a, b) => {
          if (typeof a[key] === "number" && typeof b[key] === "number") {
            return a[key] - b[key];
          }
          if (typeof a[key] === "string" && typeof b[key] === "string") {
            return a[key].localeCompare(b[key]);
          }
          return 0;
        },
        sortDirections: ["ascend", "descend"] as SortOrder[],
        fixed: key === "Game Info" ? "left" : undefined,
        className: key === "Game Info" ? "fixed-column-left" : undefined,
        width: key === "Game Info" ? 100 : undefined,
        onFilter: (value, record) => record[key] === value,
        render: (value, record) => {
          const lowerKey = key.toLowerCase();
          if (key === "game info") {
            return <div>{value}</div>;
          }

          const isLineColumn = lowerKey.includes("line");

          if (!isLineColumn) {
            const lineColumnKey = keys.find((k) => {
              const lowerK = k.toLowerCase();
              return lowerK.includes("line") && lowerK.startsWith(lowerKey);
            });

            if (lineColumnKey) {
              const lineValue = record[lineColumnKey];
              let line;
              if (typeof lineValue === "string") {
                line = extractNumberFromOdds(lineValue);
              } else if (typeof lineValue === "number") {
                line = lineValue;
              }

              if (typeof value === "number" && typeof line === "number") {
                const difference = value - line;
                const red = "#ffb3b3";
                const green = "#ccffcc";
                const backgroundColor = difference > 0 ? green : red;

                return (
                  <div style={{ backgroundColor}}>
                    {value}
                  </div>
                );
              }
            }
          }
          return <div>{value}</div>;
        },
      }));

      setColumns(newColumns);
      setRows(table.map((row, index) => ({ id: index, ...row })));
    }, [isBettingInfoEnabled, result]); // Runs when toggleState or result changes

    const plottableKeys = Object.keys(table[0]).flatMap(item => {
      if (item === 'Game Info') return []; // Remove 'Game Info'
      if (item.includes('(Line)')) return []; // Remove '(Line)' items
      if (item === 'Cmp/Att') return ['Completions', 'Pass Atts']; // Split 'Cmp/Att'
      return item;
  });

  const handlePlotSelect = (plotSelection: string) => {
    setSelectedPlot(plotSelection);
  };


  const onBettingToggleChange = (bettingInfoToggle: boolean) => {
    setIsBettingInfoEnabled(bettingInfoToggle);
  };
    
    return (
      <>
        <div className="betting-info-toggle">
          <Switch onChange={onBettingToggleChange}
          checkedChildren="Betting On"
          unCheckedChildren="Betting Off"
          />
        </div>
        <div className="data-grid">
          <Table
            className="ant-table-wrapper"
            columns={columns}
            dataSource={rows}
            rowClassName={(record, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')}
            pagination={{ position: ['bottomCenter'] }}
            scroll={{ x: 'max-content' }}
          />
        </div>
        <PlotButton menuItems={plottableKeys} onPlotSelect={handlePlotSelect}></PlotButton>
        {selectedPlot && <PlotDisplay table={table} selectedPlot={selectedPlot} isBettingInfoEnabled={isBettingInfoEnabled} />}
      </>

    );
  },
});

