import { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import { TableRow } from "./DisplayDataTool"; 

type PlotDisplayProps = {
  table: TableRow[];
  selectedPlot: string;
  isBettingInfoEnabled: boolean;
};

interface Dataset {
    id: number;
    label: string;
    data: number[];
    backgroundColor?: string[];
    grouped?: boolean;
    categoryPercentage?: number;
  }
  
interface PlotData {
    labels: string[];
    datasets: Dataset[];
  }
  

// Given a table of [{colName: val, ... }, {...}, ...]
// Return array of all data in that column.
const extractColumnData = (table, columnName, isOdds=false) => {
    if (isOdds) {
      return table.map(row => row[columnName]).map(val => extractNumberFromOdds(val));
    }
  
    if (columnName === 'Completions' || columnName === 'Pass Atts') {
      // Need to get data from CMP/ATT column.
      const cmpAttData = table.map(row => row['Cmp/Att']);
  
      if (columnName === 'Completions') {
        const completions = cmpAttData.map(entry => Number(entry.split('/')[0]));
        return completions;
      } else {
        const attempts = cmpAttData.map(entry => Number(entry.split('/')[1]));
        return attempts;
      }
    }
    return table.map(row => row[columnName]);
}
  
  // Given a string like "o25 (-100)",
  // return the odds line, or 25 in this case.
const extractNumberFromOdds = (value) => {
    const match = value.match(/[a-zA-Z]\s*(\d+(\.\d+)?)/); // Match number (integer or decimal) after a letter
    return match ? parseFloat(match[1]) : null;
};

const PlotDisplay = ({ table, selectedPlot, isBettingInfoEnabled }: PlotDisplayProps) => {
  const [plotData, setPlotData] = useState<PlotData | null>(null);
  const [plotOptions, setPlotOptions] = useState<ChartOptions>({});

  useEffect(() => {
    if (!selectedPlot || table.length === 0) return;

    const keys = Object.keys(table[0]);
    const xAxisLabels = extractColumnData(table, "Game Info");
    const data = extractColumnData(table, selectedPlot);

    const lineLabel = selectedPlot + " (Line)";
    const hasLineData = keys.includes(lineLabel) && isBettingInfoEnabled;
    const lineData = hasLineData ? extractColumnData(table, lineLabel, true) : [];

    const red = "#ffb3b3";
    const green = "#ccffcc";

    const colors = hasLineData
      ? data.map((d, i) => (d > lineData[i] ? green : red))
      : data.map(() => "#2186c4");

    const options: ChartOptions = {
      responsive: true,
      plugins: {
        legend: {
          labels: {
            generateLabels: () => {
              return hasLineData
                ? [
                    { text: "Over", fillStyle: green, strokeStyle: green, lineWidth: 1 },
                    { text: "Under", fillStyle: red, strokeStyle: red, lineWidth: 1 },
                  ]
                : [{ text: selectedPlot, fillStyle: "#2186c4", lineWidth: 1 }];
            },
          },
        },
      },
    };

    setPlotOptions(options);
    setPlotData({
      labels: xAxisLabels,
      datasets: [
        {
          id: 1,
          label: selectedPlot,
          data: data,
          backgroundColor: colors,
          grouped: false,
          categoryPercentage: 0.2,
        },
        {
          id: 2,
          label: lineLabel,
          data: lineData,
          categoryPercentage: 0.4,
        },
      ],
    });
  }, [table, selectedPlot]);

  return plotData ? 
  <Bar 
    data={plotData} 
    // @ts-expect-error Not sure why there is a type mismatch. Look into it later.
    options={plotOptions} /> 
  : null;
};

export default PlotDisplay;
