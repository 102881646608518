import React, { useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const PlotButton = ({ menuItems, onPlotSelect }) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (item) => {
    // Pass the selected plot item to the parent component
    onPlotSelect(item);
  };

  const menu = (
    <Menu>
      {menuItems.map((item, index) => (
        <Menu.Item key={index} onClick={() => handleClick(item)}>
          {item}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      onVisibleChange={(flag) => setVisible(flag)}
      visible={visible}
    >
      <Button>
        Plot <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default PlotButton;